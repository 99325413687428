@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .portofolio-card{
        width: 100%;
        height: auto;
        padding: 5px;
        border-radius: 5%;
        @apply bg-bgSecondary
    }

    .project-view{
        position: relative;
        cursor: pointer;
    }

    .project-view img{
        border-radius: 5%;
        width: 100%;
        height: auto;
        object-fit: cover;
        opacity: 1;
        transition: .5 ease-in;
    }
    
    .project-view .background{
        transition: .2s ease-in;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
        font-size: 1.2rem;
        opacity: 0;
    }

    .project-view:hover img{
        opacity: 0.5;
    }
    .project-view:hover .background{
        opacity: 1;
    }
}