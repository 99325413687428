@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  font-size: 16px;
}

@media screen and (max-width: 1060px) {
  :root{
    font-size: 14px;
  }
}

* {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
}

.container{
  margin-left: auto;
  margin-right: auto;
}